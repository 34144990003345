import {get} from 'ys-admin'


/**
 *  获取任务检查风险详情
 * @param {Object} params
 * @returns {Object}
 */
export const getProblemsList = function (params) {
  return get('/comment/List', params)
};

/**
 *  获取专项任务检查风险详情
 * @param {Object} params
 * @returns {Object}
 */
export const getProblemsInfo = function (params) {
  return get('/comment/GetInfo', params)
};
/**
 *  获取专项任务检查风险详情
 * @param {Object} params
 * @returns {Object}
 */
export const getRiskInfo = function (params) {
  return get('/risk/GetInfo', params)
};